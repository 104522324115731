import React from "react";
import { Progress, Typography, Descriptions, Tooltip } from "antd";
import {
  Php,
  Mysql,
  Html5,
  Jquery,
  Stripe,
  ReactJs,
  Mongodb,
  Express,
  Postman,
  CssThree,
  Nodedotjs,
  Angularjs,
  Javascript,
  Codeigniter,
  Googlechrome,
} from "@icons-pack/react-simple-icons";
import { isMobile } from "react-device-detect";

const Skills = () => {
  const ICON_SIZE = 36;
  const skills = [
    { title: "HTML5", value: 95, icon: <Html5 color='#E34F26' size={ICON_SIZE} /> },
    { title: "CSS3", value: 95, icon: <CssThree color='#1572B6' size={ICON_SIZE} /> },
    { title: "PHP", value: 80, icon: <Php color='#777BB4' size={ICON_SIZE} /> },
    { title: "React", value: 75, icon: <ReactJs color='#61DAFB' size={ICON_SIZE} /> },
    { title: "MySQL", value: 50, icon: <Mysql color='#4479a1' size={ICON_SIZE} /> },
    { title: "Node", value: 50, icon: <Nodedotjs color='#339933' size={ICON_SIZE} /> },
    { title: "jQuery", value: 85, icon: <Jquery color='#0769AD' size={ICON_SIZE} /> },
    { title: "Express", value: 50, icon: <Express color='#000000' size={ICON_SIZE} /> },
    { title: "MongoDB", value: 50, icon: <Mongodb color='#47A248' size={ICON_SIZE} /> },
    { title: "Angular", value: 50, icon: <Angularjs color='#DD0031' size={ICON_SIZE} /> },
    { title: "JavaScript", value: 90, icon: <Javascript color='#F7DF1E' size={ICON_SIZE} /> },
    { title: "CodeIgniter", value: 75, icon: <Codeigniter color='#EE4623' size={ICON_SIZE} /> },
    { title: "Browser Extensions", value: 90, icon: <Googlechrome color='#4285F4' size={ICON_SIZE} /> },
    { title: "API Development", value: 75, icon: <Postman color='#FF6C37' size={ICON_SIZE} /> },
    { title: "Payment Gatway Integration", value: 70, icon: <Stripe color='#008CDD' size={ICON_SIZE} /> },
  ];

  return (
    <Descriptions title='My Skills' className='skills' column={isMobile ? 1 : 2} colon={false}>
      {skills.map((s, i) => (
        <Descriptions.Item
          key={i}
          label={
            <div>
              {s.icon} <Typography.Text className='ml-1'>{s.title}</Typography.Text>
            </div>
          }
        >
          <Tooltip title={`${s.title} (${s.value}%)`}>
            <Progress percent={s.value} className='pointer' />
          </Tooltip>
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default Skills;
