import React from "react";
import { Typography, Image, Row, Col, Descriptions, Space, Statistic, Button } from "antd";
import {
  CarOutlined,
  CodeOutlined,
  SmileOutlined,
  LaptopOutlined,
  CoffeeOutlined,
  FolderAddOutlined,
  VideoCameraOutlined,
  UsergroupAddOutlined,
  CloudDownloadOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import TCM_IMAGE from "../images/tcm_full.jpg";
import TCMHACK from "../images/clients/tcmhack-icon.png";
import UXARMY from "../images/clients/uxarmy-icon.png";
import BRANDER from "../images/clients/brander-icon.png";
import WHYPHY from "../images/clients/autolikertinder.png";

const About = () => {
  const { Title, Link, Text } = Typography;
  const {
    REACT_APP_NAME,
    REACT_APP_LIVE,
    REACT_APP_BLOG,
    REACT_APP_EMAIL,
    REACT_APP_MOBILE,
    REACT_APP_GITHUB,
    REACT_APP_ADDRESS,
    REACT_APP_TAGLINE,
    REACT_APP_LEETCODE,
    REACT_APP_HACKERRANK,
    REACT_APP_HACKEREARTH,
    REACT_APP_DOWNLOAD_CV,
  } = process.env;

  const hobbies = [
    { title: "Roaming", icon: <CarOutlined className='font-34px' /> },
    { title: "Games", icon: <LaptopOutlined className='font-34px' /> },
    { title: "Movies", icon: <VideoCameraOutlined className='font-34px' /> },
    { title: "Music", icon: <CustomerServiceOutlined className='font-34px' /> },
    { title: "Development", icon: <CodeOutlined className='font-34px' /> },
  ];

  const accomplishments = [
    { title: "Happy Clients", icon: <UsergroupAddOutlined />, value: "4+" },
    { title: "Projects Completed", icon: <FolderAddOutlined />, value: "16+" },
    { title: "Positive Feedback", icon: <SmileOutlined />, value: "95+" },
    { title: "Cups of Coffee", icon: <CoffeeOutlined />, value: "16+" },
  ];

  const clients = [
    { title: "TCMHACK", icon: TCMHACK, href: REACT_APP_BLOG },
    { title: "UXARMY", icon: UXARMY, href: "https://www.uxarmy.com" },
    { title: "VIDEOSOCIALS", icon: BRANDER, href: "https://videosocials.net" },
    { title: "WHYPHY LLC", icon: WHYPHY, href: "http://autolikertinder.com" },
  ];

  const gutterSize = isMobile ? 10 : 16;

  return (
    <Row gutter={[gutterSize, 0]}>
      <Col span={8}>
        <Image src={TCM_IMAGE} preview={false} />
      </Col>
      <Col span={16}>
        <Descriptions
          title='About Me'
          column={isMobile ? 1 : 2}
          extra={[
            <Button
              key='cv'
              shape='round'
              type='primary'
              target='_blank'
              href={REACT_APP_DOWNLOAD_CV}
              icon={<CloudDownloadOutlined />}
            >
              Download CV
            </Button>,
          ]}
        >
          <Descriptions.Item label='Name'>{REACT_APP_NAME}</Descriptions.Item>
          <Descriptions.Item label='Live'>{REACT_APP_LIVE}</Descriptions.Item>
          <Descriptions.Item label='Mobile'>{REACT_APP_MOBILE}</Descriptions.Item>
          <Descriptions.Item label='Work'>{REACT_APP_TAGLINE}</Descriptions.Item>
          <Descriptions.Item label='Email'>{REACT_APP_EMAIL}</Descriptions.Item>
          <Descriptions.Item label='Address'>{REACT_APP_ADDRESS}</Descriptions.Item>
          <Descriptions.Item label='Github'>
            <Link href={REACT_APP_GITHUB} target='_blank'>
              {REACT_APP_GITHUB}
            </Link>
          </Descriptions.Item>
          {/* <Descriptions.Item label='Blog'>
            <Link href={REACT_APP_BLOG} target='_blank'>
              {REACT_APP_BLOG}
            </Link>
          </Descriptions.Item> */}
          <Descriptions.Item label='LeetCode'>
            <Link href={REACT_APP_LEETCODE} target='_blank'>
              {REACT_APP_LEETCODE}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label='HackerEarth'>
            <Link href={REACT_APP_HACKEREARTH} target='_blank'>
              {REACT_APP_HACKEREARTH}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label='HackerRank'>
            <Link href={REACT_APP_HACKERRANK} target='_blank'>
              {REACT_APP_HACKERRANK}
            </Link>
          </Descriptions.Item>
        </Descriptions>

        <Space size={[gutterSize, gutterSize]} align='start' direction='vertical'>
          <div>
            <Title level={5}>Hobbies</Title>
            <Space size={[gutterSize, gutterSize]} wrap>
              {hobbies.map((h, i) => (
                <div key={i} className='hobbies'>
                  {h.icon}
                  <Text className='d-block' strong>
                    {h.title}
                  </Text>
                </div>
              ))}
            </Space>
          </div>
          <div>
            <Title level={5}>Accomplishments</Title>
            <Space className='accomplishments' size={[gutterSize, gutterSize]} wrap>
              {accomplishments.map((a, i) => (
                <Statistic key={i} title={a.title} value={a.value} prefix={a.icon} />
              ))}
            </Space>
          </div>
          <div>
            {/* <Title level={5}>Clients</Title> */}
            <Space className='clients' size={[gutterSize, gutterSize]} wrap>
              {clients.map((h, i) => (
                <Link key={i} href={h.href} target='_blank' className='d-block'>
                  <Image src={h.icon} className='client-logo' preview={false} />
                  <Text className='d-block font-14px' strong>
                    {h.title}
                  </Text>
                </Link>
              ))}
            </Space>
          </div>
        </Space>
      </Col>
    </Row>
  );
};

export default About;
