import React from "react";
import { MessageOutlined } from "@ant-design/icons";
import { Carousel, Typography, Rate, Avatar } from "antd";
import { SAL_ERIOTES, DANNY, BRYCE } from "../image";
import { isBrowser, isDesktop, isMobile } from "react-device-detect";

const Testimonials = () => {
  const { Title, Paragraph } = Typography;
  const data = [
    {
      rate: 5,
      name: "Danny James",
      date: "Feb 11th, 2021",
      message: "Very speedy developer and really great at his job. Will use again",
      image: DANNY,
      position: "Co-Founder- Click Theory Media",
    },
    {
      rate: 5,
      name: "Sal Eriotes",
      date: "Feb 8th, 2021",
      message: "The best software developer you could ask for. Great prices as well!",
      image: SAL_ERIOTES,
      position: "Co-Founder- WHYPHY, LLC",
    },
    {
      rate: 5,
      name: "Sal Eriotes",
      date: "Oct 28th, 2020",
      message:
        "Tikam is THE BEST!! I LOVE working with him. Very reasonable on pricing and ALWAYS overdelivers!! The quality he puts out in his coding is impeccable!",
      image: SAL_ERIOTES,
      position: "Co-Founder- WHYPHY, LLC",
    },
    {
      rate: 5,
      name: "Bryce Alderson",
      date: "May 23rd, 2020",
      message: "Amazing work!! was able to build every functionality we needed into our chrome extensions! highly recommend :)",
      image: BRYCE,
      position: "Co-Founder- WHYPHY, LLC",
    },
    {
      rate: 5,
      name: "Sal Eriotes",
      date: "May 19th, 2020",
      message: "Tikam is an amazing developer. You need to hire him for an development work in the software space. :)",
      image: SAL_ERIOTES,
      position: "Co-Founder- WHYPHY, LLC",
    },
    {
      rate: 5,
      name: "Sal Eriotes",
      date: "Mar 23rd, 2020",
      message:
        "AMAZING EXPERIENCE!! Tikam is very easy to work with and he does a FANTASTIC job in developing a chrome extension for me and my team. He also completed it very fast! I will DEFINITELY be using him in the near future and keeping him on my team of developers!",
      image: SAL_ERIOTES,
      position: "Co-Founder- WHYPHY, LLC",
    },
  ];

  return (
    <div className='testimonials'>
      <Title level={isBrowser ? 2 : 4} className='text-center'>
        What my clients says?
      </Title>

      <Carousel
        autoplay
        style={{ height: "calc(100vh - 282px)" }}
        className={"m-auto justify-center d-flex align-center " + (isBrowser ? "w-80" : "w-100")}
      >
        {data.map((d, i) => (
          <div key={i} className={"d-flex align-center " + (isMobile ? "column-reverse" : "")}>
            <div className={"flex-1 " + (isMobile ? "text-center" : "p-2")}>
              {isBrowser && <MessageOutlined style={{ fontSize: 50 }} />}

              <Title level={3} className='mt-2'>
                Client Review
              </Title>
              <Paragraph strong style={{ minHeight: isBrowser ? 120 : 82 }} className='d-flex align-center'>
                {d.message}
              </Paragraph>
              <Paragraph className='m-0'>- {d.date}</Paragraph>
              <Title level={4} className='mt-0'>
                {d.name}
              </Title>
              <Paragraph className='m-0'>{d.position}</Paragraph>
              <Rate value={d.rate} disabled />
            </div>
            <div className={"flex-1 d-flex align-center " + (isDesktop ? "p-2" : "")}>
              <div className='client'>
                <Avatar src={d.image} size={isMobile ? 80 : 164} style={{ border: "5px solid" }} />
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
