import tcmImage from "./images/tcm.png";
import sal_eriotes from "./images/clients/sal_eriotes.jpg";
import bryce from "./images/clients/bryce.jpg";
import danny from "./images/clients/danny-james.webp";
import tcmhack from "./images/clients/tcmhack-icon.png";
import brander from "./images/clients/brander-icon.png";
import autoLiker from "./images/clients/autolikertinder.png";
import lekoupon from "./images/clients/lekoupon-icon.png";
import innerCircles from "./images/clients/inner-circles-icon.png";
import pirahas from "./images/clients/pirahas.png";
import treeTesting from "./images/clients/tree-testing-icon.svg";
import uxarmy from "./images/clients/uxarmy-icon.png";
import way2college from "./images/clients/way2college.png";
import fbdownloaderext from "./images/clients/fbdownloaderexticon.png";
import findthatfont from "./images/clients/findthatfont.png";
import saveimgastype from "./images/clients/saveimgastype.png";
import fvidsdownload from "./images/clients/fvidsdownload.png";
import character from "./images/clients/character.jpg";
import youtubeClone from "./images/clients/youtube-clone.png";
import useradvocate from "./images/clients/useradvocate.jpg";
import tcmhackLogo from "./images/clients/tcmhack-logo.png";
import ytSkip from "./images/clients/Auto-Skip-690x460.png";
import ytSkipIcon from "./images/clients/autoskip.png";
import currencyIcon from "./images/clients/currency_icon.png";
import youtubeIcon from "./images/clients/youtube_icon.png";
import hackerearth from "./images/clients/hackerearth.png";
import unsplash from "./images/clients/unsplash.png";
import bookmarks from "./images/clients/bookmarks.jpg";
import cleanCache from "./images/clients/clean-cache.png";
import volumeBooster from "./images/clients/volume-booster.png";
import vimeoDownloader from "./images/clients/vimeo-downloader.png";
import twitterDownloader from "./images/clients/twitter-downloader.png";
import ytAdBlocker from "./images/clients/yt-ad-blocker.png";
import instagraDownloader from "./images/clients/instagram-downloader.jpg";
import socialDownloader from "./images/clients/social-downloader.png";

export const TCM_IMAGE = tcmImage;
export const SAL_ERIOTES = sal_eriotes;
export const BRYCE = bryce;
export const DANNY = danny;
export const TCMHACK = tcmhack;
export const BRANDER = brander;
export const TINDER_AUTO_LIKER = autoLiker;
export const LEKOUPON = lekoupon;
export const INNER_CIRCLES = innerCircles;
export const PIRAHAS = pirahas;
export const TREE_TESTING = treeTesting;
export const UXARMY = uxarmy;
export const WAY2COLLEGE = way2college;
export const FB_DOWN_EXT = fbdownloaderext;
export const FIND_THAT_FONT = findthatfont;
export const SAVE_IMG_TYPE = saveimgastype;
export const FB_VIDS_DOWN = fvidsdownload;
export const CHARACTER = character;
export const YOUTUBE_CLONE = youtubeClone;
export const USERADVOCATE = useradvocate;
export const TCMHACK_LOGO = tcmhackLogo;
export const YT_SKIP = ytSkip;
export const YT_SKIP_ICON = ytSkipIcon;
export const CURRENCY_ICON = currencyIcon;
export const YOUTUBE_ICON = youtubeIcon;
export const HACKEREARTH = hackerearth;
export const UNSPLASH = unsplash;
export const BOOKMARKS = bookmarks;
export const CLEAN_CACHE = cleanCache;
export const VOLUME_BOOSTER = volumeBooster;
export const VIMEO_DOWNLOADER = vimeoDownloader;
export const TWITTER_DOWNLOADER = twitterDownloader;
export const YT_AD_BLOCKER = ytAdBlocker;
export const INSTAGRAM_DOWNLOADER = instagraDownloader;
export const SOCIAL_DOWNLOADER = socialDownloader;
