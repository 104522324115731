import React, { useState } from "react";
import TCM_IMAGE from "./images/tcm.jpeg";
import { Layout, Menu, Breadcrumb, Avatar, Typography, Button } from "antd";
import {
  MenuOutlined,
  TeamOutlined,
  MailOutlined,
  HeartTwoTone,
  PhoneOutlined,
  GlobalOutlined,
  LaptopOutlined,
  GithubOutlined,
  SolutionOutlined,
  InfoCircleOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { isMobile, isBrowser } from "react-device-detect";
import About from "./components/About";
import Posts from "./components/Posts";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import Portfolio from "./components/Portfolio";
import Experience from "./components/Experience";
import Testimonials from "./components/Testimonials";

const App = () => {
  const { Title, Link, Text } = Typography;
  const { Header, Content, Sider, Footer } = Layout;
  const [activeNavKey, setActiveNavKey] = useState("about");
  const { REACT_APP_BLOG, REACT_APP_EMAIL, REACT_APP_MOBILE, REACT_APP_GITHUB, REACT_APP_TAGLINE, REACT_APP_NAME } = process.env;
  const topBarNavs = [
    {
      key: "call",
      title: "Call",
      value: REACT_APP_MOBILE,
      href: "callto:" + REACT_APP_MOBILE,
      icon: <PhoneOutlined />,
    },
    {
      key: "mail",
      title: "Email",
      value: REACT_APP_EMAIL,
      href: "mailto:" + REACT_APP_EMAIL,
      icon: <MailOutlined />,
    },
    { key: "blog", title: "Blog", icon: <GlobalOutlined />, value: REACT_APP_BLOG },
    { key: "gith", title: "Github", icon: <GithubOutlined />, value: REACT_APP_GITHUB },
  ];
  const sideBarNavs = [
    { key: "about", title: "About Me", icon: <InfoCircleOutlined />, component: <About /> },
    { key: "skill", title: "My Skills", icon: <LaptopOutlined />, component: <Skills /> },
    { key: "portf", title: "Portfolio", icon: <NotificationOutlined />, component: <Portfolio /> },
    { key: "workh", title: "Experience", icon: <SolutionOutlined />, component: <Experience /> },
    { key: "testi", title: "Testimonials", icon: <TeamOutlined />, component: <Testimonials /> },
    { key: "posts", title: "Latest Posts", icon: <GlobalOutlined />, component: <Posts /> },
    { key: "conta", title: "Contact Me", icon: <PhoneOutlined />, component: <Contact /> },
  ];
  const activeNav = sideBarNavs.find((nav) => nav.key === activeNavKey);

  return (
    <Layout>
      <Header className='d-flex align-center justify-between pl-3 pr-3 bg-white'>
        <Link href='/' className='d-flex align-center justify-between'>
          <Avatar src={TCM_IMAGE} shape='square' size='large' />
          <Title level={5} className='m-0 ml-2'>
            {REACT_APP_NAME}
            <small className='d-block'>{REACT_APP_TAGLINE}</small>
          </Title>
        </Link>

        <Menu theme='light' className='top-menu' mode='horizontal' overflowedIndicator={<MenuOutlined />}>
          {isBrowser &&
            topBarNavs.map((tb) => (
              <Menu.Item key={tb.key} title={`${tb.title}: ${tb.value}`}>
                <Button type='link' icon={tb.icon} target='_blank' href={tb.href || tb.value}>
                  <Text>{tb.title}</Text>
                </Button>
              </Menu.Item>
            ))}

          {isMobile &&
            sideBarNavs.map((nav) => (
              <Menu.Item key={nav.key} icon={nav.icon} onClick={() => setActiveNavKey(nav.key)}>
                {nav.title}
              </Menu.Item>
            ))}
        </Menu>
      </Header>
      <Layout>
        {isBrowser && (
          <Sider width={200} style={{ position: "fixed", left: 0, height: "100%" }}>
            <Menu mode='inline' selectedKeys={[activeNavKey]} className='pt-2 h-100' onClick={({ key }) => setActiveNavKey(key)}>
              {sideBarNavs.map((nav) => (
                <Menu.Item key={nav.key} icon={nav.icon}>
                  {nav.title}
                </Menu.Item>
              ))}
            </Menu>
          </Sider>
        )}
        <Layout className='pl-3 pr-3' style={{ marginLeft: isBrowser ? 200 : 0 }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>{activeNav.title}</Breadcrumb.Item>
          </Breadcrumb>

          <Content className='bg-white p-2 m-0' style={{ minHeight: "calc(100vh - 74px - 54px - 65px)" }}>
            {activeNav.component}
          </Content>

          <Footer className='justify-center font-bold'>
            Made with <HeartTwoTone twoToneColor='#eb2f96' style={{ marginLeft: 5, marginRight: 5 }} /> at Jaipur, Rajasthan. Copyright
            2016 - {new Date().getFullYear()}. All Rights Reserved.
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default App;
