import React from "react";
import { Typography, Avatar, List, Card } from "antd";
import { WAY2COLLEGE, UXARMY } from "../image";
import { isBrowser } from "react-device-detect";

const Experience = () => {
  const { Title, Text, Link } = Typography;
  const data = [
    {
      logo: UXARMY,
      location: "Jaipur, Rajasthan (India)",
      company: "UXArmy Pte Ltd, Jaipur (Raj)",
      position: "Software Engineer",
      website: "https://www.uxarmy.com",
      work_time: "1st Aug, 2017 to Present",
      worked_on: "HTML, CSS, JavaScript, jQuery, React/Redux, Angular, Node/Express/MongoDB, PHP (Codeigniter, Yii1)/MySQL, Git",
      description: [
        "- Managed and Added new features/apis into the Web Application (PHP/MySql/Yii1).",
        '- Created a full fledged Web Application called "Tree Testing/Card Sorting/Participatory Design" using MERN Stack. Main Responsibility: Frontend (React/Redux).',
        "- Worked on a Trading Project using Angular",
        <Text>
          - Created a Chrome Extension{" "}
          <Link href='https://chrome.google.com/webstore/detail/gckbkibdgldakpaepgppcjkjpcbfmjec' target='_blank' strong>
            "UserAdvocate"
          </Link>{" "}
          for the UserTesting purpose.
        </Text>,
      ],
    },
    {
      logo: WAY2COLLEGE,
      location: "Jaipur, Rajasthan (India)",
      company: "Capellasoft Solutions Pvt Ltd, Jaipur (Raj)",
      position: "Ass. Development Manager",
      website: "https://www.capellasoft.org",
      work_time: "23rd Jun, 2016 to 31st July 2017",
      worked_on: "HTML, CSS, JavaScript, jQuery, PHP (Joomla)/MySQL, Git, SEO (Google Analytics/Google Webmaster)",
      description: [
        "- Managed and Added new features into the Web Application (PHP/MySql/Joomla).",
        "- Worked on User Interface for improving the UI and Responsiveness.",
        "- Worked on Google Analytics/Webmaster for SEO Purposes.",
        "- Worked on Angular/Ionic to create a Mobile Application.",
      ],
    },
    // {
    //   logo: TCMHACK,
    //   company: 'TCMHACK, Jaipur (Raj)',
    //   position: '- CEO & Founder',
    //   website: 'https://www.tcmhack.in',
    //   work_time: '2016 to Present',
    //   worked_on:
    //     'AWS (EC2/SES), React/Redux, Browser Extensions, HTML, CSS, JavaScript, jQuery, PHP (Wordpress/Codeigniter), Git, FFMPEG',
    //   description: [
    //     '- Managed and Added new features into the Web Application (PHP/MySql/Codeigniter).',
    //     '- Worked on User Interface for improving the UI and Responsiveness.',
    //     '- Created & Managed a Web Applications using PHP/MYSql/Codeigniter Frmework.',
    //     '- Chrome Extensions by using the React/JavaScript/jQuery & Google Chrome Extension APIs',
    //   ],
    // },
  ];

  return (
    <div className='experience'>
      <Title level={isBrowser ? 2 : 4} style={{ marginBottom: "1rem" }}>
        Experience
      </Title>

      {data.map((d, i) => (
        <Card hoverable key={i} className={data.length - 1 === i ? "mb-0" : "mb-2"} bodyStyle={{ padding: "1rem" }}>
          <Title level={isBrowser ? 3 : 5} className='d-flex align-center gap-2'>
            <Avatar src={d.logo} />
            <Link href={d.website} target='_blank'>
              {d.company}
            </Link>
          </Title>
          <Text level={5} strong className='mb-1'>
            {d.position} | {d.location} | {d.work_time}
          </Text>
          <Text className='d-block'>
            <strong>Technologies Used: </strong> {d.worked_on}
          </Text>
          <Text strong>Description:</Text>
          <List
            size='small'
            dataSource={d.description}
            renderItem={(item) => <List.Item style={{ padding: "0.25rem 0.5rem", border: 0 }}>{item}</List.Item>}
          />
        </Card>
      ))}
    </div>
  );
};

export default Experience;
