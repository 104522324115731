import React, { useEffect, useState } from "react";
import { List, Button, Typography, Image, Tag, Space } from "antd";
import { isMobile } from "react-device-detect";

const Posts = () => {
  const { Link, Text } = Typography;
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const WP_HOST_URL = "https://tcmhack.in/wp-json/wp/v2/";
    fetch(WP_HOST_URL + "posts?per_page=3")
      .then((res) => res.json())
      .then(async (results) => {
        const posts = await Promise.all(
          results.map(async (post) => {
            const response = await fetch(WP_HOST_URL + "media/" + post.featured_media);
            const result = await response.json();

            // Get Categories
            const response2 = await fetch(WP_HOST_URL + "categories?post=" + post.id);
            const categories = await response2.json();

            // Get Tags
            const response3 = await fetch(WP_HOST_URL + "tags?post=" + post.id);
            const tags = await response3.json();

            return { ...post, media_url: result.source_url, categories, tags };
          })
        );
        setPosts(posts);
      });
  }, [setPosts]);

  return (
    <List
      size='large'
      pagination={null}
      dataSource={posts}
      header='Latest Posts'
      itemLayout='vertical'
      loading={!posts.length}
      className='h-100 posts'
      loadMore={
        <Button block type='primary' href={process.env.REACT_APP_BLOG} target='_blank' loading={!posts.length} className='mt-2'>
          See All Posts
        </Button>
      }
      renderItem={(item) => (
        <List.Item
          key={item.id}
          extra={
            <Image
              preview={false}
              src={item.media_url}
              width={isMobile ? "100%" : 286}
              style={{ borderRadius: 5, border: "1px solid #eeeeee" }}
            />
          }
        >
          <Link href={item.link} target='_blank' className='font-16px' strong>
            <span dangerouslySetInnerHTML={{ __html: item.title.rendered }}></span>
          </Link>

          <Space className='d-flex mb-1 mt-1 cats'>
            <Text>{new Date(item.date).toDateString()}</Text>
            <Text>-</Text>
            {item.categories.map((t) => (
              <Link key={t.id} href={t.link} target='_blank'>
                {t.name}
              </Link>
            ))}
          </Space>

          <p className='m-0' dangerouslySetInnerHTML={{ __html: item.excerpt.rendered }} />

          <div className='mb-2'>
            {item.tags.map((t) => (
              <Tag key={t.id} color='blue'>
                <Link href={t.link} target='_blank'>
                  #<span dangerouslySetInnerHTML={{ __html: t.name }} />
                </Link>
              </Tag>
            ))}
          </div>

          <Button href={item.link} target='_blank' type='primary'>
            Read More...
          </Button>
        </List.Item>
      )}
    />
  );
};

export default Posts;
