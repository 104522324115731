import React, { useState } from 'react';
import { Typography, Row, Col, Form, Input, Button, Space, Tooltip, Alert } from 'antd';
import {
  HomeOutlined,
  MailOutlined,
  SendOutlined,
  PhoneOutlined,
  SkypeOutlined,
  GlobalOutlined,
  LinkedinFilled,
  FacebookFilled,
  TwitterOutlined,
  YoutubeOutlined,
  WhatsAppOutlined,
  InstagramOutlined,
} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';

const Contact = () => {
  const [form] = Form.useForm();
  const [state, setState] = useState({ alertMSg: '', isLoading: false });
  const { isLoading, alertMSg } = state;
  const { Title, Text, Link } = Typography;
  const {
    REACT_APP_BLOG,
    REACT_APP_LIVE,
    REACT_APP_EMAIL,
    REACT_APP_SKYPE,
    REACT_APP_MOBILE,
    REACT_APP_YOUTUBE,
    REACT_APP_TWITTER,
    REACT_APP_FACEBOOK,
    REACT_APP_WHATSAPP,
    REACT_APP_LINKEDIN,
    REACT_APP_INSTAGRAM,
    REACT_APP_BLOG_API,
  } = process.env;

  const bottomNavs = [
    { title: 'Facebook', link: REACT_APP_FACEBOOK, icon: <FacebookFilled /> },
    { title: 'LinkedIn', link: REACT_APP_LINKEDIN, icon: <LinkedinFilled /> },
    { title: 'Instagram', link: REACT_APP_INSTAGRAM, icon: <InstagramOutlined /> },
    { title: 'Twitter', link: REACT_APP_TWITTER, icon: <TwitterOutlined /> },
    { title: 'YouTube', link: REACT_APP_YOUTUBE, icon: <YoutubeOutlined /> },
    { title: 'WhatsApp', link: REACT_APP_WHATSAPP, icon: <WhatsAppOutlined /> },
    { title: 'Skype', link: REACT_APP_SKYPE, icon: <SkypeOutlined /> },
  ];

  const onSubmit = async () => {
    try {
      const values = await form.validateFields();
      setState({ ...state, isLoading: true });

      fetch(REACT_APP_BLOG_API + ':5000/api/email/contact', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((r) => r.json())
        .then((res) => {
          setState({ ...state, isLoading: false, alertMSg: res.msg });
          form.resetFields();
        });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  return (
    <div className="text-center contact">
      <Title className="m-0" level={isMobile ? 2 : 1}>
        Contact Me
      </Title>
      <Text strong>Any question or remarks? Just write me a message.</Text>

      <Row gutter={[isMobile ? 0 : 16, isMobile ? 0 : 16]} className="m-2 text-left">
        <Col span={10} className="p-3 left">
          <Title level={3}>Contact Information</Title>
          <Text>Fill up the forn and i'll be get back to you within 24 hours.</Text>
          <Title level={4} className="d-flex align-center ccl">
            <PhoneOutlined className="font-22px mr-1" />
            <Link href={'callto:' + REACT_APP_MOBILE}>{REACT_APP_MOBILE}</Link>
          </Title>
          <Title level={4} className="d-flex align-center ccl">
            <MailOutlined className="font-22px mr-1" />
            <Link href={'mailto:' + REACT_APP_EMAIL}>{REACT_APP_EMAIL}</Link>
          </Title>
          <Title level={4} className="d-flex align-center ccl">
            <HomeOutlined className="font-22px mr-1" /> {REACT_APP_LIVE}
          </Title>
          <Title level={4} className="d-flex align-center ccl">
            <GlobalOutlined className="font-22px mr-1" />
            <Link href={REACT_APP_BLOG} target="_blank">
              {REACT_APP_BLOG}
            </Link>
          </Title>

          <Space size={[10, 10]} className={'bottom-navs ' + (isMobile ? 'pt-1' : '')}>
            {bottomNavs.map((b, i) => (
              <Tooltip key={i} title={b.title}>
                <Link href={b.link} target="_blank">
                  {b.icon}{' '}
                </Link>
              </Tooltip>
            ))}
          </Space>
        </Col>
        <Col span={14} className="p-3 right">
          <Title level={3}>Fill up the form</Title>
          {alertMSg && <Alert showIcon type="success" className="mb-1" message="Email has been sent successfully." />}
          <Form form={form} layout="vertical" requiredMark={'optional'} onValuesChange={(v, a) => console.log(v, a)}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter your name' }]}>
              <Input placeholder="Enter your name..." type="text" size="large" />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please enter your email' }]}>
              <Input placeholder="Enter your email..." type="email" size="large" />
            </Form.Item>
            <Form.Item label="Mobile" name="mobile">
              <Input placeholder="Enter your mobile..." type="tel" size="large" />
            </Form.Item>
            <Form.Item label="Message" name="message" rules={[{ required: true, message: 'Please enter your message' }]}>
              <Input.TextArea placeholder="Enter your message..." size="large" />
            </Form.Item>
            <Form.Item className="m-0">
              <Button
                size="large"
                shape="round"
                type="primary"
                block={isMobile}
                onClick={onSubmit}
                loading={isLoading}
                icon={<SendOutlined />}
              >
                Send
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
