import React, { useState } from "react";
import { Typography, Radio, Avatar, List, Badge } from "antd";
import {
  UXARMY,
  TCMHACK,
  UNSPLASH,
  BOOKMARKS,
  CHARACTER,
  CLEAN_CACHE,
  FB_DOWN_EXT,
  HACKEREARTH,
  USERADVOCATE,
  YT_SKIP_ICON,
  YOUTUBE_ICON,
  YT_AD_BLOCKER,
  CURRENCY_ICON,
  SAVE_IMG_TYPE,
  FIND_THAT_FONT,
  VOLUME_BOOSTER,
  VIMEO_DOWNLOADER,
  SOCIAL_DOWNLOADER,
  TINDER_AUTO_LIKER,
  TWITTER_DOWNLOADER,
  INSTAGRAM_DOWNLOADER,
} from "../image";
import { isBrowser } from "react-device-detect";

const Portfolio = () => {
  const types = { ALL: 0, WEB: 1, EXT: 2 };
  const [filterBy, setFilterBy] = useState(null);
  const { Title, Link, Text } = Typography;
  const getExtLink = (id) => "https://chrome.google.com/webstore/detail/" + id;

  const data = [
    {
      type: types.WEB,
      img: TCMHACK,
      title: "TCMHACK Blog",
      link: "https://tcmhack.in",
      description: "It is one of the most popular website. One can find the latest news & best tutorails for Web Deveopment.",
    },
    {
      type: types.WEB,
      img: UXARMY,
      title: "UX Tookit- Tree Testing/Card Sorting",
      link: "https://ux-toolkit.uxarmy.com",
      description: "UXArmy UX Design tool takes an anticipatory approach allowing stakeholders to express their perspective.",
    },
    {
      type: types.WEB,
      img: SOCIAL_DOWNLOADER,
      title: "Social Video Downloader",
      link: "https://socialdownloader.in",
      description:
        "It is one of the best Social Video Downloader. You can download the videos from Facebook, Instagram, YouTube, Twitter & Vimeo.",
    },
    {
      type: types.EXT,
      img: SOCIAL_DOWNLOADER,
      title: "Social Video Downloader",
      link: getExtLink("bphnmjlejonaeiapcnimchlmhbpbdiip"),
      description: "Downloads videos from social networks like Facebook, Instagram, Twitter & Vimeo in one click.",
    },
    {
      type: types.EXT,
      img: YT_SKIP_ICON,
      title: "YouTube Ads Skipper",
      link: getExtLink("gngacemkofalidlfggfppjnhlfkidcda"),
      description: "YouTube Ads Skipper is simple extension which Auto Skips the Advertisements from the videos.",
    },
    {
      type: types.EXT,
      img: CURRENCY_ICON,
      title: "Currency Converter",
      link: getExtLink("djijpkidiloakhgopmijjdcancpfekma"),
      description: "Currency Converter - Converts one currency into another with one simple click.",
    },
    {
      type: types.EXT,
      img: USERADVOCATE,
      title: "UserAdvocate",
      link: getExtLink("gckbkibdgldakpaepgppcjkjpcbfmjec"),
      description: "Use the UserAdvocate extension to participate in user studies you received via UXArmy.",
    },
    {
      type: types.EXT,
      img: TINDER_AUTO_LIKER,
      title: "Auto Liker for Tinder 2.0",
      link: getExtLink("kigkkoaookbdloeellianbgcniajfbob"),
      description: "Tinder extension that automatically swipes right (Like) 👍 on everyone for you.",
    },
    {
      type: types.EXT,
      img: VOLUME_BOOSTER,
      title: "Volume Booster 2.0",
      link: getExtLink("ejignddmhjbhbflngofijehfekikpaba"),
      description: "Increase your volume with a sliding bar & boost the sound from your computer with the highest of quality!",
    },
    {
      type: types.EXT,
      img: FB_DOWN_EXT,
      title: "Video Downloader For FB",
      link: getExtLink("jhpemhkhbbhpefadnjnkgpnbpohcamce"),
      description: "Download videos directly from Facebook in HD quality with one simple click.",
    },
    {
      type: types.EXT,
      img: YT_AD_BLOCKER,
      title: "Adblocker for YT",
      link: getExtLink("ldbflapfegfcoipoeophhjbgfcllmjip"),
      description: "Adblocker for YT - Removes all ads while on Youtube® with one simple click.",
    },
    {
      type: types.EXT,
      img: CLEAN_CACHE,
      title: "Clean Cache",
      link: getExtLink("jcjbmjhjgflfbcilklndgmhgampgdmnk"),
      description:
        "Easily clears all of the data that clogs up your memory & battery: cookies, browsing history, cached images & files, and more!",
    },
    {
      type: types.EXT,
      img: VIMEO_DOWNLOADER,
      title: "Vimeo Video Downloader",
      link: getExtLink("bhoelgakcfbagdljilfjigmlbgefcoom"),
      description: "Download videos directly from Vimeo in HD quality with one simple click.",
    },
    {
      type: types.EXT,
      img: INSTAGRAM_DOWNLOADER,
      title: "Video & Photo Downloader For IG",
      link: getExtLink("kggfegclemhiainkddbhhgobheohnjha"),
      description: "Download videos & Photos directly from Instagram in HD quality with one simple click.",
    },
    {
      type: types.EXT,
      img: TWITTER_DOWNLOADER,
      title: "Twitter Video & Photo Downloader",
      link: getExtLink("fbncoglhbmdbhojgcleaaloaagjldhlg"),
      description: "Download videos & photos directly from Twitter in HD quality with one simple click.",
    },
    {
      type: types.EXT,
      img: SAVE_IMG_TYPE,
      title: "Save Image As Type 2.0",
      link: getExtLink("hhmnengcgekecnlielgcfcbjanjlnifg"),
      description: "Save image as PNG, JPG, PDF, or WebP by context menu (Right-Clicking) on image.",
    },
    {
      type: types.EXT,
      img: FIND_THAT_FONT,
      title: "Find That Font",
      link: getExtLink("alannkadjimikdglagjkfdnmcdjkahhc"),
      description:
        "It tells you the Font details of the text on hover like 'Family', 'Style', 'Weight', 'Size', 'Line Height', and the 'Color' etc.",
    },

    {
      type: types.EXT,
      img: HACKEREARTH,
      title: "HackerEarth Challenges",
      link: getExtLink("infidkkgnkakeecjakbpeejpdknncpdk"),
      description: "HackerEarth Extension keeps you updated with upcoming challenges and other resources on HackerEarth.",
    },
    {
      type: types.WEB,
      img: FB_DOWN_EXT,
      title: "Facebook Video Downloader",
      link: "https://fbvidsdownload.com",
      description: "Facebook Video Downloader is one of the best tools available online for downloading the videos from Facebook.",
    },
    {
      type: types.WEB,
      img: YOUTUBE_ICON,
      title: "YouTube Clone",
      link: "https://youtube.tcmhack.in",
      description: "It is just a YouTube Clone. I developed it while learning ReactJS..",
    },
    {
      type: types.WEB,
      img: TCMHACK,
      title: "Weather Info",
      link: "https://weather.tcmhack.in",
      description: "It provides the information of current & forecast weather for a location.",
    },
    {
      type: types.WEB,
      img: TCMHACK,
      title: "Bank IFSC Finder",
      link: "https://bank-ifsc.tcmhack.in",
      description: "Get any bank ifsc codes, search ifsc by bank name or search bank by ifsc code.",
    },

    {
      type: types.EXT,
      img: UNSPLASH,
      title: "TCM – Unsplash",
      link: getExtLink("ddknoicjjimjmfkhhggdcfnaadnjakko"),
      description: "Download HD images free from your browser in just one click.",
    },
    {
      type: types.EXT,
      img: BOOKMARKS,
      title: "Bookmarks Management",
      link: getExtLink("djdlkoihbhgbjfabpgpclknhjoalmkjk"),
      description: "Simple extension to manage all of your bookmarks easily.",
    },
    {
      type: types.EXT,
      img: CHARACTER,
      title: "Character Counter",
      link: getExtLink("endjefbdafoojmddnfgfiifgldeobfkc"),
      description: "Count the characters, words, sentences, paragraphs & whitespaces in a text.",
    },
  ];

  return (
    <div className='portfolio'>
      <Title level={isBrowser ? 3 : 5} className='d-flex align-center justify-between'>
        <Text>My Portfolio</Text>

        <Radio.Group defaultValue={types.ALL} onChange={(e) => setFilterBy(e.target.value)}>
          <Radio.Button value={types.ALL}>All</Radio.Button>
          <Radio.Button value={types.WEB}>Websites</Radio.Button>
          <Radio.Button value={types.EXT}>Extensions</Radio.Button>
        </Radio.Group>
      </Title>

      <List
        itemLayout='horizontal'
        className='portfolio-list'
        dataSource={filterBy ? data.filter((d) => d.type === filterBy) : data}
        renderItem={(d) => (
          <List.Item>
            <Badge.Ribbon text={d.type === types.EXT ? "Extension" : "Website"} color={d.type === types.EXT ? "cyan" : "red"}>
              <List.Item.Meta
                avatar={<Avatar src={d.img} size='large' />}
                title={
                  <Link href={d.link} target='_blank' disabled={!d.link}>
                    {d.title}
                  </Link>
                }
                description={d.description}
              />
            </Badge.Ribbon>
          </List.Item>
        )}
      />
    </div>
  );
};

export default Portfolio;
